// Core
import React from "react"
// import scrollTo from "gatsby-plugin-smoothscroll"
// import { StaticImage } from "gatsby-plugin-image"

// Layout
import { Layout } from "../layouts"

// Components
import { Wrapper, Banner, Accordion, Seo } from "../components"

// Icons
// import { BsArrowRightShort } from "react-icons/bs"
import CompanyJobIcon from "../assets/icons/metrics-right.inline.svg"

const CompanyPage = () => (
  <Layout>
    <Seo title="About Company" />
    {/* <section className="company-hero">
      <Wrapper>
        <div className="company-hero__row">
          <section className="company-hero__description">
            <span className="company-hero__title">About Outloud.ai</span>
            <h1 className="company-hero__subtitle">
              Online businesses have Google Analytics. Offline don't. We changed
              that.
            </h1>
            <p className="company-hero__text">
              Offline retailers have little visibility into what is happening on
              their front lines. We built Outloud to give them full transparency
              on their customer service, sales, and ops.
            </p>
            <button
              className="company-hero__button"
              onClick={() => scrollTo("#company-job")}
            >
              See job openings
              <BsArrowRightShort className="company-hero__button--arrow" />
            </button>
          </section>
          <StaticImage
            className="company-hero__img"
            src="../assets/company-ipad.png"
            alt="Communication with business"
            quality="90"
            layout="fullWidth"
            placeholder="blurred"
          />
        </div>
      </Wrapper>
    </section> */}

    <section id="company-job" className="company-job">
      <Wrapper>
        <CompanyJobIcon className="company-job__icon" />
        <div className="company-job__description">
          <h2 className="company-job__title">Current Job Openings</h2>
          <p className="company-job__text">
            Join us to build a product with bleeding-edge tech, to learn a bunch
            of new stuff, and to have fun together!
          </p>
        </div>
        <Accordion
          title="Product Management Summer Intern"
          role="Product | Strategy"
          posted="1 week"
        >
          <h3 className="accordion__content--title">You will be:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Driving product development from small features to entirely new
              products
            </li>
            <li className="accordion__content--item">
              Working closely with our existing clients on understanding their
              needs, analyzing data, and designing / running experiments
            </li>
            <li className="accordion__content--item">
              Working with designers and engineers to implement and build new
              features
            </li>
            <li className="accordion__content--item">
              Assisting us on the strategic projects
            </li>
          </ul>
          <h3 className="accordion__content--title">We are looking for:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Hands-on work experience in a mix of any of the following:
              product, VC, management consulting, or corporate
              strategy/development roles in technology companies
            </li>
            <li className="accordion__content--item">
              Experience in and/or passion for technology and AI
            </li>
            <li className="accordion__content--item">
              Focused on delivering results
            </li>
            <li className="accordion__content--item">
              Ability to wear many hats working at a fast-growing start-up
            </li>
          </ul>
        </Accordion>
        <Accordion
          title="AI/ML Summer Intern"
          role="Machine learning | Engineering"
          posted="2 weeks"
        >
          <h3 className="accordion__content--title">You will be:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Implementing and training state-of-the-art models in ASR and NLP
              areas
            </li>
            <li className="accordion__content--item">
              Participating in data acquisition strategy
            </li>
            <li className="accordion__content--item">
              Ideating and prototyping new data products built with AI
              techniques
            </li>
            <li className="accordion__content--item">
              Working hands-on with engineers to turn ideas and prototypes into
              full-fledged products and ship them to users
            </li>
          </ul>
          <h3 className="accordion__content--title">We are looking for:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Currently enrolled in a Master’s degree in Computer Science or a
              related technical field.
            </li>
            <li className="accordion__content--item">
              Keen to train and test deep learning models on large datasets.
            </li>
            <li className="accordion__content--item">
              Experience with python, scientific packages, jupyter.
            </li>
            <li className="accordion__content--item">
              Eagerness to work with C++ codebase (c++ experience is not
              required but good to have).
            </li>
          </ul>
        </Accordion>
        <Accordion
          title="Senior Python back-end engineer"
          role="Back-end | Engineering"
          posted="1 day"
        >
          <h3 className="accordion__content--title">You will be:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Contributing to platform architecture and individual components
              design.
            </li>
            <li className="accordion__content--item">
              Working with multiple codebases and building different python (and
              sometimes non-python) components (REST, WebSockets, gRPC).
            </li>
            <li className="accordion__content--item">
              Deploying modules and state-of-the-art ML models on cloud.
            </li>
            <li className="accordion__content--item">
              Monitoring system logs, fixing bugs and ensuring that the platform
              works properly.
            </li>
            <li className="accordion__content--item">
              Building a product with a bleeding-edge tech, learning a bunch of
              new cool stuff and having fun with us!
            </li>
          </ul>
          <h3 className="accordion__content--title">We are looking for:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Excellent knowledge of Python with 5+ years of industry
              experience.
            </li>
            <li className="accordion__content--item">
              Good knowledge of cloud technologies, such as AWS or Google Cloud
              and at least 1 year of industry experience with either.
            </li>
            <li className="accordion__content--item">
              Good knowledge of SQL/NoSQL DB.
            </li>
            <li className="accordion__content--item">
              Good knowledge of C++ is a huge bonus.
            </li>
            <li className="accordion__content--item">
              Following good development practices (unit and integration tests,
              git, logging, CI/CD).
            </li>
            <li className="accordion__content--item">
              Experience with ML models in production is a plus.
            </li>
            <li className="accordion__content--item">
              Experience with numerical packages, such as numpy, pytorch,
              tensorflow is a plus.
            </li>
          </ul>
        </Accordion>
        <Accordion
          title="Senior JavaScript full stack engineer"
          role="Full stack | Engineering"
          posted="1 day"
        >
          <h3 className="accordion__content--title">You will be:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Participate in design and planning discussions, contribute
              architecture ideas.
            </li>
            <li className="accordion__content--item">
              Develop and test new user-facing features.
            </li>
            <li className="accordion__content--item">
              Write highly scalable, reusable and testable code.
            </li>
            <li className="accordion__content--item">
              Ensure the technical feasibility of UI/UX design.
            </li>
            <li className="accordion__content--item">
              Optimize application for maximum speed and performance.
            </li>
            <li className="accordion__content--item">
              Building a product with a bleeding-edge tech, learning a bunch of
              new cool stuff and having fun with us!
            </li>
          </ul>
          <h3 className="accordion__content--title">We are looking for:</h3>
          <ul className="accordion__content--list">
            <li className="accordion__content--item">
              Excellent knowledge of JavaScript with 5+ years of industry
              experience.
            </li>
            <li className="accordion__content--item">
              Experience with Node.js and related frameworks.
            </li>
            <li className="accordion__content--item">
              Experience in designing REST API with microservice architecture.
            </li>
            <li className="accordion__content--item">
              Good knowledge of SQL/NoSQL DB.
            </li>
            <li className="accordion__content--item">
              Experience in building large, complex, React-based SPA.
            </li>
            <li className="accordion__content--item">
              Following good development practices (unit tests, git, logging,
              CI/CD).
            </li>
            <li className="accordion__content--item">
              Good knowledge of cloud technologies, such as AWS is a Plus.
            </li>
          </ul>
        </Accordion>
      </Wrapper>
    </section>

    <Banner />
  </Layout>
)

export default CompanyPage
